import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import React from 'react'

import SpeedTest from '../../../components/data-centers/speed-test'
import Layout from '../../../components/layout'
import Seo from '../../../components/Seo';
import StructuredData from '../../../components/structured-data'

type Props = {
  location: Record<string, unknown>
  data: {
    hero: { childImageSharp: { fluid: FluidObject } }
    region: { childImageSharp: { fluid: FluidObject } }
    lansingACenter: { childImageSharp: { fluid: FluidObject } }
    lansingBCenter: { childImageSharp: { fluid: FluidObject } }
    wpPage: {
      uri: string
      seo: Record<string, unknown>
    }
  }
};

const url = 'https://us-test.liquidweb.name/100mb.bin';

const PageUSCentral: React.FC<Props> = ({ data }) => {
  const { hero, region, lansingACenter, lansingBCenter, wpPage } = data

  return (
    <Layout>
      <Seo post={wpPage} />
      <StructuredData pageType="webPage" />
      <div className="py-10 px-5 lg:py-20 relative text-white">
        <div className="relative z-30 container">
          <h1 className="text-white mb-4">US-Central Region</h1>
          <h2 role="doc-subtitle" className="font-semibold">
            Our Homebase &amp; Michigan Data Centers
          </h2>
        </div>
        <div
          className="absolute w-full h-full top-0 left-0 z-20"
          style={{
            backgroundColor: `rgba(46,60,75,0.93)`,
          }}
          role="presentation"
        ></div>
        <GatsbyImage
          image={hero.childImageSharp.gatsbyImageData}
          alt="US-Central Region"
          className="w-full h-full left-0 top-0 z-10 data-center-bg"
          style={{ position: `absolute` }} />
      </div>
      <div className="bg-lw-lt-gray">
        <div className="px-8 container py-8 lg:py-20 grid grid-cols-5 gap-10">
          <div className="col-span-5 lg:col-span-3">
            <h2 className="mb-6">Overview</h2>
            <div className="mb-2">
              Our US-Central region is home to our original data centers and the
              founding location of our company. In this region we currently
              operate two fully-owned data centers located in Lansing, Michigan.
              In addition to these data centers we also operate a location in
              Plymouth. Our highly-trained staff can be found available 24/7/365
              on-site in our Michigan data centers. Our most savvy customers can
              take advantage of our two US regions to provide geographic
              redundancy.
            </div>
          </div>
          <div className="col-span-5 lg:col-span-2">
            <GatsbyImage image={region.childImageSharp.gatsbyImageData} alt="US-Central Region" />
          </div>
        </div>
      </div>
      <div className="px-8 py-8 lg:py-16 container">
        <h2 className="mb-6">US-Central Region</h2>
        <div className="grid grid-cols-2 gap-4 mb-8">
          <div className="col-span-2 lg:col-span-1">
            <div>
              <span className="font-bold">Location:</span> Lansing, Michigan
            </div>
            <div>
              <span className="font-bold">Data Centers:</span> 2
            </div>
            <div>
              <span className="font-bold">Providers:</span> Level 3, Verizon,
              Cogent, Comcast, Equinix
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <div>
              <span className="font-bold">Established:</span> 1997
            </div>
            <div>
              <span className="font-bold">Zones:</span> 2
            </div>
            <div>
              <span className="font-bold">Offices:</span> 3
            </div>
          </div>
        </div>
        <h4 className="pb-6 text-xl lg:text-2xl">
          Data Centers in This Region
        </h4>
        <div className="grid grid-cols-4 gap-10">
          <div className="col-span-4 lg:col-span-1">
            <GatsbyImage
              image={lansingACenter.childImageSharp.gatsbyImageData}
              alt="Lansing Data Center - A" />
          </div>
          <div className="col-span-4 lg:col-span-1">
            <div className="font-bold">Lansing Data Center - A</div>
            <div>Size: 32,000 sq ft</div>
            <div>Capacity: 8,000 Servers</div>
            <div>SSAE-16 Compliant</div>
          </div>
          <div className="col-span-4 lg:col-span-1">
            <GatsbyImage
              image={lansingBCenter.childImageSharp.gatsbyImageData}
              alt="Lansing Data Center - A" />
          </div>
          <div className="col-span-4 lg:col-span-1">
            <div className="font-bold">Lansing Data Center - B</div>
            <div>Size: 90,000 sq ft</div>
            <div>Capacity: 25,000 Servers</div>
            <div>SSAE-16 Compliant</div>
          </div>
        </div>
      </div>
      <div className="bg-lw-purple">
        <div className="px-8 py-8 container text-center text-white">
          <div className="text-lg uppercase font-semibold mb-4">
            Curious how our network performs?
          </div>
          <div className="text-2xl font-semibold mb-8">
            Test The Speed of Our US-Central Region Now!
          </div>
          <SpeedTest url={url} />
          <div className="text-xs">
            Note: Speeds are limited by your local device&apos;s connection. Users on
            a mobile device using 3G will see significantly slower speeds than
            those hardwired to a DSL/Cable Modem.
          </div>
        </div>
      </div>
      <div className="px-8 py-8 lg:py-20 grid grid-cols-2 container gap-10">
        <div className="col-span-2 lg:col-span-1">
          <div className="text-lg font-semibold mb-1">Power System</div>
          <div className="border-t-2 border-lw-blue max-w-33 m-0" />
          <ul className="mt-1 list-disc pl-10 mt-5">
            <li>Expandable 13,500 kVA Utility Power Feeds</li>
            <li>
              Multiple ASCO Closed Transition Bypass Isolation Transfer Switches
            </li>
            <li>Multiple N+1 Generac Diesel Generators</li>
            <li>Multiple N+1 Powerware 9395 550 kVA UPS systems</li>
            <li>Liebert &amp; Eaton Power Distribution Units</li>
            <li>Multiple Service Entrance Feeds</li>
          </ul>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="text-lg font-semibold mb-1">Network Gear</div>
          <div className="border-t-2 border-lw-blue max-w-33 m-0" />
          <ul className="mt-1 list-disc pl-10 mt-5">
            <li>
              Redundant Fiber Entrance Expandable to 1,840 Gigabits Per Second
            </li>
            <li>
              Multiple Redundant Gigabit Ethernet Links to Data Center 1 and
              Data Center 2
            </li>
            <li>
              Fully Redundant Cisco 6509 Sup720 and Nexus 7000 Distribution
              Switches
            </li>
            <li>Redundant Gigabit Ethernet Links to Each Rack Switch</li>
            <li>Cisco 4948 48-Port 10/100/1000 Rack Switches</li>
          </ul>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="text-lg font-semibold mb-1">Cooling</div>
          <div className="border-t-2 border-lw-blue max-w-33 m-0" />
          <ul className="mt-1 list-disc pl-10 mt-5">
            <li>
              Multiple Liebert 20, 22, 30 and 45 Ton upflow and downflow AC Unit
            </li>
            <li>
              Stand alone HVAC systems that don&apos;t allow for large scale failure
            </li>
            <li>Designed For Addition of Air-Side Economization</li>
          </ul>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="text-lg font-semibold mb-1">Security</div>
          <div className="border-t-2 border-lw-blue max-w-33 m-0" />
          <ul className="mt-1 list-disc pl-10 mt-5">
            <li>24/7/365 Manned Facilities</li>
            <li>
              CCTV Security Cameras Covering Inside, Outside and All Entrances
              of Data Centers
            </li>
            <li>
              Site Entrances Controlled By Electronic Perimeter Access Card
              System
            </li>
            <li>Sites Remotely Monitored By 3rd Party Security Company</li>
            <li>Entrances Secured by Mantraps with Interlocking Doors</li>
            <li>SSAE-16 &amp; HIPAA Compliant, Safe Harbor Certified</li>
          </ul>
        </div>
      </div>
      <div className="p-8 container">
        <Link to="/data-centers" aria-label="Back to Data Centers">
          &#x3c; Back to Data Centers
        </Link>
      </div>
    </Layout>
  );
}

export default PageUSCentral

export const query = graphql`query USCentralPage {
  wpPage(slug: {eq: "us-central"}) {
    uri
    chatDepartment
    seo {
      ...postSeoFields
    }
  }
  hero: file(relativePath: {eq: "about-us/data-centers/traffic-spikes.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
  region: file(
    relativePath: {eq: "about-us/data-centers/data-center-us-central.png"}
  ) {
    childImageSharp {
      gatsbyImageData(
        quality: 100
        width: 500
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
  lansingACenter: file(relativePath: {eq: "about-us/data-centers/lansing-a.png"}) {
    childImageSharp {
      gatsbyImageData(
        quality: 100
        width: 400
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
  lansingBCenter: file(relativePath: {eq: "about-us/data-centers/lansing-b.png"}) {
    childImageSharp {
      gatsbyImageData(
        quality: 100
        width: 400
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
}
`
